

















import Vue from 'vue'
export default Vue.component('HOOrderListMenu', {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
})
